import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../css/sponsors.css";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import Navbar from "../components/navbar";

const Sponsors = () => {
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    let sponsorsArray = [];
    getDocs(collection(db, "sponsors")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let sponsor = {
          desc: doc.data().desc,
          imgLink: doc.data().imgLink,
          webLink: doc.data().webLink,
          name: doc.data().name,
        };
        sponsorsArray.push(sponsor);
      });
      setSponsors(sponsorsArray);
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="app__sponsors">
        <div className="sponsors__left">
          <div className="sponsorsBg"></div>
          <h1>Sponsors</h1>
          <p>
            Over the years, Antaragni has built its undisputed image in the
            industry as a result of the invaluable partnerships it has forged.
            We value you, and you value us. That's how we grow together. The
            contributions from these partnerships has always provided
            entertainment galore for our audience, and made everything possible.
            And thus, the potential opportunities we have for you is a testament
            to the purpose we seek to fulfil!
          </p>
        </div>
        <div className="sponsors__right">
          <div className="row align-content-center justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1 row_sponsors">
            {sponsors.map((sponsor) => {
              return (
                <div className="mb-5 col sponsors__imageDiv">
                  <a target="_blank" rel="noreferrer" href={sponsor.webLink}>
                    <LazyLoadImage
                      className="sponsors__img"
                      src={sponsor.imgLink}
                      alt={sponsor.name}
                    />
                    <p className="sponsors__desc">{sponsor.desc}</p>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sponsors;
