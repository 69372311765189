import React from "react";
import img1 from "../assets/fast-time.png";
import Navbar from "../components/navbar";

export default function Schedule() {
  return (
    <>
      <div className="soon">
        <Navbar />

        <img src={img1}></img>
        <h1>Schedule</h1>
        <h1> Coming Soon!</h1>
      </div>
    </>
  );
}
