import React from "react";
import "../css/contacts.css";
// import ContactsCard from "../components/contact-card";
import ContactCard from "./ContactCard";
import Nav3d from "./navbarContact/nav";
import "../css/contactCard.css";
import Navbar from "../components/navbar";
const contacts = [
  {
    name: "Vatsal Chaudhary",
    post: "Head, Events & Competitions",
    image:
      "https://drive.google.com/uc?export=view&id=1tlg61aHE254FnoXDwpMAPq04eD89sI5J",
    fb_userid: "https://www.facebook.com/vatsal.chaudhary.35977",
    phone: "+917985163578",
    gmail: "vatsal.chaudhary2001@gmail.com",
    ant_userid: "vatsal",
    linkedin: "https://www.linkedin.com/in/vatsal-chaudhary-459398183/",
    row: 1,
  },
  {
    name: "Shobhit Singh",
    post: "Head, Events & Competitions",
    image:
      "https://drive.google.com/uc?export=view&id=1zKbcidRIHDYIlGlOvD_sAVI6f9Fw0KSw",
    fb_userid: "https://www.facebook.com/profile.php?id=100009016034787",
    phone: "+918743943887",
    gmail: "singhshobhit304@gmail.com",
    ant_userid: "shobhit",
    linkedin: "https://www.linkedin.com/in/shobhit-singh-801/",
    row: 1,
  },
  {
    name: "Ajit Meena",
    post: "Head, Events & Competitions",
    image:
      "https://drive.google.com/uc?export=view&id=1CwJRDYJlGPJKFwJL5JQ4nhMePVt6xf8m",
    fb_userid: "https://www.facebook.com/ajitm2608?mibextid=ZbWKwL",
    phone: "+917357455462",
    gmail: "",
    ant_userid: "ajit",
    linkedin: " https://www.linkedin.com/in/ajit-meena-b07a0b196",
    row: 1,
  },

  {
    name: "Sarthak Gupta",
    post: "Head, Finance",
    image:
      "https://drive.google.com/uc?export=view&id=16fYncN5HcmazehaKlgmb_arLLHUxXv7f",
    fb_userid: "https://www.facebook.com/sarthak.gupta.2991",
    phone: "+918306630350",
    gmail: "sarthak299g@gmail.com",
    ant_userid: "sarthak",
    linkedin: "https://www.linkedin.com/in/sarthak-gupta-653760197",
    row: 2,
  },

  {
    name: "Yash Burnwal",
    post: "Head, Marketing",
    image:
      "https://drive.google.com/uc?export=view&id=1vda45Bx5KO9mbU9pBvXFJ7ggT5jKkJcu",
    fb_userid: "https://www.facebook.com/burnwalyash/",
    phone: "+918354833012",
    gmail: "byash.iitk@gmail.com",
    ant_userid: "yash",
    linkedin: "https://www.linkedin.com/in/yash-burnwal-4849281ba/",
    row: 3,
  },
  {
    name: "Dhruv Mittal",
    post: "Head, Marketing",
    image:
      "https://drive.google.com/uc?export=view&id=1pBp-3ed6bJtmzhkj66TAjss2oRT6QH61",
    fb_userid: "https://www.facebook.com/dhruv.mittal.73594",
    phone: "+919818898351",
    gmail: "dmittal2110@gmail.com",
    ant_userid: "dhruv",
    linkedin: "https://www.linkedin.com/in/dhruv-mittal-62065219a",
    row: 3,
  },

  {
    name: "Tanishka Agrawal",
    post: "Head, Media & Publicity",
    image:
      "https://drive.google.com/uc?export=view&id=1tdHSWgM4F6BxTXb96NIH9ede7PWGM3sP",
    fb_userid: "https://www.facebook.com/tanishka.agrawal.528",
    phone: "+917987921274",
    gmail: "agl29tani@gmail.com",
    ant_userid: "tanishka",
    linkedin: "https://www.linkedin.com/in/tanishka-agrawal-8b69051a7",
    row: 4,
  },
  {
    name: "Anand Patwa",
    post: "Head, Media & Publicity",
    image:
      "https://drive.google.com/uc?export=view&id=1fhRTpqES_HvHeV-w6M6w6bppGavQM84B",
    fb_userid: "https://www.facebook.com/anand.patwa.777/",
    phone: "+919421845539",
    gmail: "anand11072001@gmail.com",
    ant_userid: "anand",
    linkedin: "https://www.linkedin.com/in/anand-patwa-274632191/",
    row: 4,
  },

  {
    name: "Ridham Jain",
    post: "Head, Public Relations",
    image:
      "https://drive.google.com/uc?export=view&id=18s3kp1AgDoN169QcOWHWTj4UPttTkMN0",
    fb_userid: "https://www.facebook.com/profile.php?id=100009074329116",
    phone: "+919166898463",
    gmail: "ridham@antaragni.in",
    ant_userid: "ridham",
    linkedin: "https://www.linkedin.com/in/ridham-jain-b857311b6/",
    row: 5,
  },
  {
    name: "Mohit Kumar",
    post: "Head, Public Relations",
    image:
      "https://drive.google.com/uc?export=view&id=146TEanYB2k9CiTXlp_FpD0aVaXV8_ttS",
    fb_userid: "https://www.facebook.com/profile.php?id=100037413513684",
    phone: "+918929772823",
    gmail: "mohitdbg199@gmail.com",
    ant_userid: "mohit",
    linkedin: "https://www.linkedin.com/in/mohit-kumar-07734a1ab",
    row: 5,
  },

  {
    name: "Rishi Prakash",
    post: "Head, Security",
    image:
      "https://drive.google.com/uc?export=view&id=1neCzShclpPk7T7E45dFGSBpEk6ihgxh0",
    fb_userid: "https://www.facebook.com/profile.php?id=100038051462788",
    phone: "+919801015858",
    gmail: "rishiprakash98@gmail.com",
    ant_userid: "rishi",
    linkedin: "http://linkedin.com/in/rishiroyz",
    row: 6,
  },
  {
    name: "Prathmesh Singh Dhakad",
    post: "Head, Security",
    image:
      "https://drive.google.com/uc?export=view&id=1lWkQ372-lg5NOfHE24OBQLRZ9m3SW1Ge",
    fb_userid: "https://www.facebook.com/prathmesh.singhdhakad/",
    phone: "+919630808590",
    gmail: "prathmeshdhakad@gmail.com",
    ant_userid: "prathmesh",
    linkedin: "https://www.linkedin.com/in/prathmesh-singh-dhakad-774828196/",
    row: 6,
  },

  {
    name: "Navshruti Singh",
    post: "Head, Show Management",
    image:
      "https://drive.google.com/uc?export=view&id=1STV1PRCL8QGBBGo6Z4WpUU5bG2D2_Qa5",
    fb_userid: "https://www.facebook.com/navshruti.singh.52",
    phone: "+917037893777",
    gmail: "singhnavshruti@gmail.com",
    ant_userid: "navshruti",
    linkedin: "https://www.linkedin.com/in/navshrutisingh/",
    row: 7,
  },
  {
    name: "Abhishek",
    post: "Head, Show Management",
    image:
      "https://drive.google.com/uc?export=view&id=14sIcq8a6dRYruFEc1l60IuVFpK7NFoBa",
    fb_userid: "https://www.facebook.com/abhitam8853",
    phone: "+918853875986",
    gmail: "abhishekg6377@gmail.com",
    ant_userid: "abhishekg",
    linkedin: "https://www.linkedin.com/in/abhishek-g-5696581aa",
    row: 7,
  },
  {
    name: "Mahesh Mandar",
    post: "Head, Show Management",
    image:
      "https://drive.google.com/uc?export=view&id=1uLfAq2pLRJ71K_iSuHcYJ98WeFJffgh1",
    fb_userid: "https://www.facebook.com/profile.php?id=100070061164279",
    phone: "+917073112199",
    gmail: "maheshmandar9@gmail.com",
    ant_userid: "maheshm",
    linkedin: "https://www.linkedin.com/in/mahesh-mandar-991321213",
    row: 7,
  },

  {
    name: "Navya Ratnan",
    post: "Head, Design",
    image:
      "https://drive.google.com/uc?export=view&id=1JvW04wSv_qEudH7CUbhK0C28oOw7kwYi",
    fb_userid: "https://www.facebook.com/profile.php?id=100005155472642",
    phone: "+917593800951",
    gmail: "navyaratnan243@gmail.com",
    ant_userid: "navya",
    linkedin: "https://www.linkedin.com/in/navya-ratnan/",
    row: 8,
  },
  {
    name: "Abhishek Rajwanshi",
    post: "Head, Design",
    image:
      "https://drive.google.com/uc?export=view&id=1duvr85Htjg13tMEWHKC9kSGhM7y_UtXk",
    fb_userid: "https://www.facebook.com/abhishek.rajvanshi.39/",
    phone: "+919696174674",
    gmail: "rajwanshi2155501@gmail.com",
    ant_userid: "abhishekr",
    linkedin: "https://www.linkedin.com/in/abhishek-rajwanshi-3693421bb/",
    row: 8,
  },

  {
    name: "Sandeep Kumar",
    post: "Head, Web & App",
    image:
      "https://drive.google.com/uc?export=view&id=1wbM4alcRbCpkOktzps_QNsSUX_Zr-ZtU",
    fb_userid: "https://facebook.com/sandeep.bijarnia.kumar",
    phone: "+918239850413",
    gmail: "sandeepskr1721@gmail.com",
    ant_userid: "admin",
    linkedin: "https://linkedin.com/in/sandeep-bijarnia-14812b201",
    row: 9,
  },
  {
    name: "Navneet Singh",
    post: "Head, Web & App",
    image:
      "https://drive.google.com/uc?export=view&id=12g1eQvUlXUjgbwzVb92OUZq-RPUxugd6",
    fb_userid: "https://www.facebook.com/navneet2810/",
    phone: "+918546003658",
    gmail: "singhnavneet9640@gmail.com",
    ant_userid: "admin",
    linkedin: "https://www.linkedin.com/in/navneet-singh-0b849820b/",
    row: 9,
  },

  {
    name: "Mayank Sarwa",
    post: "Festival Coordinator",
    image:
      "https://drive.google.com/uc?export=view&id=1Z4mfnS6s4N_JEJ8oPvyY7lpc2K-jFX2Y",
    fb_userid: "https://www.facebook.com/mayank.sarwa.180",
    phone: "+919079035719",
    gmail: "sarwamayank@gmail.com",
    ant_userid: "mayank",
    linkedin: "https://www.linkedin.com/in/mayank-sarwa/",
    row: 10,
  },
  {
    name: "Nikunj Mundhra",
    post: "Festival Coordinator",
    image:
      "https://drive.google.com/uc?export=view&id=1abgkr6Fbow7plk3K8zEC04Alq2PdnoDM",
    fb_userid: "https://www.facebook.com/nikunj.mundhra.77",
    phone: "+918529779807",
    gmail: "mundhranikunj27@gmail.com",
    ant_userid: "nikunj",
    linkedin: "https://www.linkedin.com/in/nikunj-mundhra-467047a6",
    row: 10,
  },
];

export default function Contacts() {
  const contactList = Array([], [], [], [], [], [], [], [], [], []);
  const posts = Array(10);
  contacts.forEach((contact) => {
    contactList[contact.row - 1].push(contact);
    posts[contact.row-1] = contact.post;
  })
  

  return (
    <div className="contactContainer">
      <div className="bgContact"></div>
      <Navbar/>
      <div className="contactHeading">
        <h1 >Contacts</h1>
      </div>
      <div className="contactNav">
        <Nav3d posts= {posts}/>
      </div>
      <div className="contactGrid">
        {contactList.map((contacts, index) => (
          <>
            <div id={index+1} className="ConHeading"> 
            <h2>{contacts[0].post}</h2>
            </div>
            <ul className="cardscon">
              {contacts.map((contact) => (
                <ContactCard contact={contact}/>
              ))}
            </ul>
          </>
        ))}

      </div>
    </div>
  );
}
