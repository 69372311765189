import { React, useEffect, useState } from "react";
import img1 from "../assets/fast-time.png";
import Navbar from "../components/navbar";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

export default function StarAttractions() {
  const [data, setData] = useState([]);

  useEffect(() => {
    let attractionData = [];
    getDocs(collection(db, "starAttractions")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = {
          id: doc.id,
          contentLink: doc.data().contentLink,
          desc: doc.data().desc,
          title: doc.data().title,
          type: doc.data().type,
        };
        attractionData.push(data);
      });
      setData(attractionData);
      console.log(attractionData);
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="soon">
        <img src={img1}></img>
        <h1>Star Attractions</h1>
        <h1> Coming Soon!</h1>
      </div>
    </>
  );
}
