import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import styles from "../css/media.module.css";
import MediaCard from "../components/mediaCard";
import Navbar from "../components/navbar";

export default function Media() {
  const [mediaItems, setmediaItems] = useState([]);

  useEffect(() => {
    let mediaItemsArray = [];
    getDocs(collection(db, "media")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let mediaItem = {
          id: doc.id,
          articleLink: doc.data().articleLink,
          data: doc.data().data,
          date: doc.data().date,
          portalImgLink: doc.data().portalImgLink,
          portalName: doc.data().portalName,
        };
        mediaItemsArray.push(mediaItem);
      });
      setmediaItems(mediaItemsArray);
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className={styles.backgroundimageMedia}></div>
      <div className={styles.media}>
        <ul>
          {mediaItems.map((mediaItem, index) => {
            return <MediaCard mediaItem={mediaItem} key={index} />;
          })}
        </ul>
      </div>
    </>
  );
}
