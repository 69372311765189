import React from "react";
import { useState } from "react";
import "./nav.css";
export default function CustomNav() {
  const [isExpanded, setExpendState] = useState(false);

  const onClick = () => {
    setExpendState(!isExpanded);
  };
  return (
    <div style={{ zIndex: "1", position: "fixed", height: "100vh" }}>
      {isExpanded == true && (
        <div id="mySidenav" className="sidenav">
          <a className="closebtn" onClick={onClick}>
            &times;
          </a>
          <a href="/starAttractions">Star Attractions</a>
          <a href="/events">Competitions</a>
          <a href="/merch">Merchandise</a>
          <a href="/schedule">Schedule</a>
          <a href="/gallery">Gallery</a>
          <a href="/media">Media</a>
          <a href="/brochure">Brochure</a>
          <a href="/sponsors">Sponsors</a>
          <a href="/contacts">Contact Us</a>
          <a href="/queries">Queries</a>
          
         
         
          
          
        </div>
      )}
      {isExpanded == false && (
        <span
          style={{
            fontSize: "30px",
            cursor: "pointer",
            position: "fixed",
            right: "20px",
            top: "20px",
            color: "white",
          }}
          onClick={onClick}
        >
          &#9776;{" "}
        </span>
      )}
    </div>
  );
}
