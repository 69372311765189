import React from "react";
import "../css/navbar.css";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import images from "../constants/images";

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className={colorChange ? "app__navbar colorChange" : "app__navbar"}>
      <div className="app__navbar-logo navbar__text">
        <a href="/">
          <img src={images.typeface} alt="synchronicity_logo" />
        </a>
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans navbar__text">
          <a href="/events">Competitions</a>
        </li>
        <li className="p__opensans navbar__text">
          <a href="/journey">Journey</a>
        </li>
        <li className="p__opensans navbar__text">
          <a href="/schedule">Schedule</a>
        </li>
        <li className="nav-item dropdown">
          <span
            className="p__opensans navbar__text dropdown-toggle"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Programs
          </span>
          <ul
            className="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <li>
              <a
                className="dropdown-item"
                href="https://roadtrips.antaragni.in/"
              >
                Roadtrips
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="https://ca.antaragni.in/">
                Campus Ambassadors
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="https://influencers.antaragni.in/">
                Influencers
              </a>
            </li>
          </ul>
        </li>
        <li className="p__opensans navbar__text">
          <a href="/sponsors">Merchandise</a>
        </li>
        <li className="nav-item dropdown">
          <span
            className="p__opensans navbar__text dropdown-toggle"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Gallery
          </span>
          <ul
            className="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <li>
              <a className="dropdown-item" href="/gallery">
                Photos
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/brochure">
                Brouchure
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="/media">
                Media
              </a>
            </li>
          </ul>
        </li>
        <li className="p__opensans navbar__text">
          <a href="/starattractions">Star Attractions</a>
        </li>
        <li className="p__opensans navbar__text">
          <a href="/sponsors">Sponsors</a>
        </li>
        <li className="p__opensans navbar__text">
          <a href="/contacts">Contact Us</a>
        </li>
        <li className="p__opensans navbar__text">
          <a href="/queries">Queries</a>
        </li>
      </ul>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <button
              content="\u00d7"
              fontSize={27}
              className="navbar__close_btn"
              onClick={() => setToggleMenu(false)}
            >
              Close
            </button>
            <ul className="app__navbar-smallscreen_links">
              {/* <li>
                <a href="#home" >
                  Home
                </a>
              </li> */}
              <li className="p__opensans navbar__text">
                <a href="/events" onClick={() => setToggleMenu(false)}>
                  Competitions
                </a>
              </li>
              <li className="p__opensans navbar__text">
                <a href="/journey" onClick={() => setToggleMenu(false)}>
                  Journey
                </a>
              </li>
              <li className="p__opensans navbar__text">
                <a href="/schedule" onClick={() => setToggleMenu(false)}>
                  Schedule
                </a>
              </li>
              <li className="nav-item dropdown">
                <span
                  className="p__opensans navbar__text dropdown-toggle"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Programs
                </span>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://roadtrips.antaragni.in/"
                      onClick={() => setToggleMenu(false)}
                    >
                      Roadtrips
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://ca.antaragni.in/"
                      onClick={() => setToggleMenu(false)}
                    >
                      Campus Ambassadors
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="https://influencers.antaragni.in/"
                      onClick={() => setToggleMenu(false)}
                    >
                      Influencers
                    </a>
                  </li>
                </ul>
              </li>
              <li className="p__opensans navbar__text">
                <a href="/sponsors" onClick={() => setToggleMenu(false)}>
                  Merchandise
                </a>
              </li>
              <li className="nav-item dropdown">
                <span
                  className="p__opensans navbar__text dropdown-toggle"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Gallery
                </span>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="/gallery"
                      onClick={() => setToggleMenu(false)}
                    >
                      Photos
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/brochure"
                      onClick={() => setToggleMenu(false)}
                    >
                      Brouchure
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="/media"
                      onClick={() => setToggleMenu(false)}
                    >
                      Media
                    </a>
                  </li>
                </ul>
              </li>
              <li className="p__opensans navbar__text">
                <a href="/starattractions" onClick={() => setToggleMenu(false)}>
                  Star Attractions
                </a>
              </li>
              <li className="p__opensans navbar__text">
                <a href="/sponsors" onClick={() => setToggleMenu(false)}>
                  Sponsors
                </a>
              </li>
              <li className="p__opensans navbar__text">
                <a href="/contacts" onClick={() => setToggleMenu(false)}>
                  Contact Us
                </a>
              </li>
              <li className="p__opensans navbar__text">
                <a href="/queries" onClick={() => setToggleMenu(false)}>
                  Queries
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

// const Navbar = () => {
//   return (
//     <nav className="navbar app__navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
//       <div className="container-fluid">
//         <a className="navbar-brand" href="/">
//           Antaragni
//         </a>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarNavDropdown"
//           aria-controls="navbarNavDropdown"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className="collapse navbar-collapse" id="navbarNavDropdown">
//           <ul className="navbar-nav">
//             <li className="nav-item">
//               <a className="nav-link" href="/events">
//                 Competitions
//               </a>
//             </li>
//             <li className="nav-item">
//               <a className="nav-link" href="/journey">
//                 Journey
//               </a>
//             </li>
//             <li className="nav-item dropdown">
//               <span
//                 className="nav-link dropdown-toggle"
//                 id="navbarDropdownMenuLink"
//                 role="button"
//                 data-bs-toggle="dropdown"
//                 aria-expanded="false"
//               >
//                 Programs
//               </span>
//               <ul
//                 className="dropdown-menu"
//                 aria-labelledby="navbarDropdownMenuLink"
//               >
//                 <li>
//                   <a
//                     class="dropdown-item"
//                     href="https://roadtrips.antaragni.in/"
//                   >
//                     Roadtrips
//                   </a>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="https://ca.antaragni.in/">
//                     Campus Ambassadors
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     class="dropdown-item"
//                     href="https://influencers.antaragni.in/"
//                   >
//                     Influencers
//                   </a>
//                 </li>
//               </ul>
//             </li>
//             <li className="nav-item">
//               <a className="nav-link" href="/starattractions">
//                 Star Attraction
//               </a>
//             </li>
//             {/* <li className="nav-item">
//               <a className="nav-link" href="/merch">
//                 Merch
//               </a>
//             </li> */}
//             <li className="nav-item dropdown">
//               <span
//                 className="nav-link dropdown-toggle"
//                 id="navbarDropdownMenuLink"
//                 role="button"
//                 data-bs-toggle="dropdown"
//                 aria-expanded="false"
//               >
//                 Gallery
//               </span>
//               <ul
//                 className="dropdown-menu"
//                 aria-labelledby="navbarDropdownMenuLink"
//               >
//                 <li>
//                   <a className="dropdown-item" href="/gallery">
//                     Photos
//                   </a>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="/brochure">
//                     Brochure
//                   </a>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="/media">
//                     Media
//                   </a>
//                 </li>
//               </ul>
//             </li>
//             <li class="nav-item">
//               <a class="nav-link" href="/merch">
//                 Merchandise
//               </a>
//             </li>
//             <li class="nav-item">
//               <a class="nav-link" href="/sponsors">
//                 Sponsors
//               </a>
//             </li>
//             <li class="nav-item">
//               <a class="nav-link" href="/contacts">
//                 Contact Us
//               </a>
//             </li>
//             <li className="nav-item">
//               <a className="nav-link" href="/queries">
//                 Queries
//               </a>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };

export default Navbar;
