import React from "react";
import Landing from "../landing/landing";
import CustomNav from "../landing/anotherNav/CustomNav";
export default function Home() {
  return (
    <>
      <CustomNav />
      <Landing />
    </>

    // <Discover />
  );
}
