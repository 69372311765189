import React from "react";
import "../css/merchandise.css";
import img1 from "../assets/merch/tshirt22.jpeg";
import Navbar from "../components/navbar";

export default function Merch() {
  return (
    <>
      <Navbar />
      <div className="top_one">
        <div className="bgmerch"></div>
        <main className="top-container">
          <div className="grid">
            <div className=" image-container">
              <picture>
                <source media="(max-width:650px)" srcSet={img1} />
                <img
                  src={img1}
                  alt="Gabrielle Parfum"
                  style={{ width: "auto" }}
                />
              </picture>

              <br></br>
              <br></br>
              <a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=314456">
                <button className="customButton" type="button" name="button">
                  Order Now
                </button>
              </a>
            </div>
            <div className=" content-container">
              <h1 className="heading_h1">Antaragni Merchandise</h1>
              <h2 className="heading_h2">Price: ₹350</h2>
              <p className="para">
                <ul>
                  <li>
                    Payment will be done by SBI i-collect. Click on order now to
                    direct to SBI i-collect.
                  </li>
                  <li>Agree to the terms and conditions and proceed further</li>
                  <li>
                    Select “Antaragni, IIT Kanpur” in the “Select Payment
                    Category” dropdown menu.
                  </li>
                  <li>
                    Enter your personal details (As shown{" "}
                    <a
                      className="linkcol"
                      href="https://antaragni.in/assets//merchandise/tshirt_instructions.png"
                    >
                      here
                    </a>
                    )
                  </li>
                  <li>Mention Tshirt Size in Remarks.</li>
                  <li>
                    Proceed to the next page and make sure your details are
                    filled in correctly.
                  </li>
                  <li>
                    Proceed to make payment and take a screenshot of the
                    receipt.
                  </li>
                  <li>Collect your Tshirt from C124 Hall-1, IIT Kanpur.</li>
                </ul>
                <p>For any queries contact- Sarthak Gupta | 8306630350</p>
                <table className="sizetable">
                  <tr>
                    <th>Size</th>
                    <th></th>
                    <th>Chest</th>
                    <th></th>
                    <th>Length</th>
                  </tr>
                  <tr>
                    <td>S</td>
                    <td></td>
                    <td>36</td>
                    <td></td>
                    <td>27</td>
                  </tr>
                  <tr>
                    <td>M</td>
                    <td></td>
                    <td>38</td>
                    <td></td>
                    <td>28</td>
                  </tr>
                  <tr>
                    <td>L</td>
                    <td></td>
                    <td>40</td>
                    <td></td>
                    <td>29</td>
                  </tr>
                  <tr>
                    <td>XL</td>
                    <td></td>
                    <td>42</td>
                    <td></td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>XXL</td>
                    <td></td>
                    <td>44</td>
                    <td></td>
                    <td>31</td>
                  </tr>
                </table>
              </p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
