import * as THREE from 'three'
import ReactDOM from 'react-dom'
import React, { Suspense, useEffect } from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import { useFrame } from '@react-three/fiber'
import { Scroll, ScrollControls } from "@react-three/drei";
import { useRef, useState } from 'react'
import { useThree } from "@react-three/fiber";
// import Gallery from "../pages/gallery.tsx";
import { Html } from '@react-three/drei'
import l1 from '../assets/screen3/21.png'
import l2 from '../assets/screen3/22.png'
import l3 from '../assets/screen1/3.png'
import l4 from '../assets/screen3/24.png'
import l5 from '../assets/screen3/25.png'
import s1 from '../assets/stats/s1.png'
import s2 from '../assets/stats/s2.png'
import s3 from '../assets/stats/s3.png'
import s4 from '../assets/stats/s4.png'
import s5 from '../assets/stats/s5.png'
import s6 from '../assets/stats/s6.png'
import statshead from '../assets/screen3/Stats.png'

function Layers(data) {
            const texture = useLoader(THREE.TextureLoader, data.imgname)
            const sizes = {
                width: window.innerWidth,
                height: window.innerHeight
            }
            return (
            <mesh position={data.pos}>

                <planeBufferGeometry attach="geometry" args={data.args} />
                <meshBasicMaterial attach="material" map={texture} transparent />
            </mesh>
            )
}

function Stats(data) {
    
    const ref = useRef();
            const [hovered, setHovered] = useState(false)
            const texture = useLoader(THREE.TextureLoader, data.imgname)
            useFrame(() => {
                if (hovered) {
                  ref.current.scale.set(1.1, 1.1, 1.1)
                } else {
                  ref.current.scale.set(1, 1, 1)
                }
              })
            const sizes = {
                width: window.innerWidth,
                height: window.innerHeight
            }
            return (
            <mesh ref={ref} position={data.pos}  onPointerOver = {()=> {setHovered(true)}} onPointerOut = {()=> {setHovered(false)}}>

                <planeBufferGeometry attach="geometry" args={data.args} />
                <meshBasicMaterial attach="material" map={texture} transparent />
            </mesh>
            )
}

export default function Screen4() {
    return (
        <>
            <Stats pos={[174,-4,-25]} args={[10,10]} imgname={s2} />
            <Stats pos={[174,4,-25]}  args={[10,10]}imgname={s4} />
            <Stats pos={[184,-4,-25]} args={[10,10]} imgname={s3} />
            <Stats pos={[184,4,-25]}  args={[10,10]}imgname={s1} />
            <Stats pos={[194,-4,-25]} args={[10,10]} imgname={s6} />
            <Stats pos={[194,4,-25]}  args={[10,10]}imgname={s5} />
            
            <Layers pos={[180,0,-66]} args={[120,60]} imgname={l5}/>
            <Layers pos={[180,0,-55]} args={[100,50]} imgname={l4}/>
            {/* <Layers pos={[180,0,-20]} args={[40,20]} imgname={l3}/> */}
            <Layers pos={[180,6,-25]} args={[15,15]} imgname={statshead}/>
            {/* <Layers pos={[163,0,-24]} args={[50,24]} imgname={l2}/> */}
            <Layers pos={[170,0,-13]} args={[30,15]} imgname={l2}/>
            <Layers pos={[205,0,-13]} args={[30,15]} imgname={l2}/>

            {/* <Layers pos={[180,0,-7]}  args={[20,10]} imgname={l1}/> */}
            <Layers pos={[200,0,-7]}  args={[20,10]} imgname={l1}/>
            <Layers pos={[220,0,-7]}  args={[20,10]} imgname={l1}/>
        </>
    )
}