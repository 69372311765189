import React from "react";
import styles from "../css/queries.module.css";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import QueryCard from "../components/queryCard";
import Navbar from "../components/navbar";

export default function Queries() {
  const [queries, setQueries] = useState([]);

  useEffect(() => {
    let QueriesArray = [];
    getDocs(collection(db, "Queries")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log(doc.data());
        let Query = {
          id: doc.id,
          name: doc.data().name,
          post: doc.data().post,
          imgLink: doc.data().imgLink,
          mobile: doc.data().mobile,
        };
        QueriesArray.push(Query);
      });
      // console.log(mediaItemsArray);
      setQueries(QueriesArray);
    });
    // console.log(mediaItems);
  }, []);
  return (
    <>
      <Navbar />
      <div className={styles.queries}>
        <h1 style={{ position: "absolute", color: "#97d2ec", top: "5rem" }}>
          For all enquiries, please contact -
        </h1>
        <div className={styles.container}>
          {queries.map((query, index) => {
            return <QueryCard query={query} key={index} />;
          })}
        </div>
      </div>
    </>
  );
}
