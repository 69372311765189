import * as THREE from 'three'
import ReactDOM from 'react-dom'
import React, { Suspense, useEffect } from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import { Stats, OrbitControls, Text, Sparkles, Text3D, Float, Center, Edges, Image } from '@react-three/drei'
import typeface from '../assets/Typeface_png.png'
import logo from '../assets/logo.png'
import { useFrame } from '@react-three/fiber'
import { Scroll, ScrollControls } from "@react-three/drei";
import { useRef, useState } from 'react'
import { useThree } from "@react-three/fiber";
// import Gallery from "../pages/gallery.tsx";
import { Html } from '@react-three/drei'
import l1 from '../assets/screen1/11.png'
import l2 from '../assets/screen1/12.png'
import l3 from '../assets/screen1/3.png'
import l4 from '../assets/screen1/14.png'
import l5 from '../assets/screen1/15.png'
import "./style.css"
import desktop from '../assets/screen2/aboutUs_web.png'
import mobile from '../assets/screen2/aboutUs_Mobile.png'
// import WoodTexture from '../assets/screen1/forest.jpg'


function Layers(data) {
    const texture = useLoader(THREE.TextureLoader, data.imgname)
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }
    return (
        <mesh position={data.pos}>

            <planeBufferGeometry attach="geometry" args={data.args} />
            <meshBasicMaterial attach="material" map={texture} transparent />
        </mesh>
    )
}

export const SparklesStory = ({ random, size, amount, ...props }) => {
    const sizes = React.useMemo(() => {
      return new Float32Array(Array.from({ length: amount }, () => Math.random() * size))
    }, [size, amount])
    return (
      <>
        <Sparkles {...props} size={random ? sizes : size} color="blue" count={amount} />
        {/* <OrbitControls /> */}
        {/* <axesHelper /> */}
        {/* <PerspectiveCamera position={[2, 2, 2]} makeDefault /> */}
      </>
    )
    }
    

export default function Screen1() {
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }
    // console.log(sizes)
    return (
        <>
            {/* <SparklesStory scale={10} position={[0, 0, -20]} size={10} amount={100} /> */}
            <Layers pos={[60, 0, -30]} args={[60, 30]} imgname={l5} />
            <Layers pos={[60, 0, -25]} args={[50, 25]} imgname={l4} />

            {/* <Image  position={[0,0,-20]} scale={15} onClick={click} url={"/bg2.jpg"} /> */}
            {sizes.width > 1200 && <Layers pos={[60, 0, -20]} args={[30, 15]} imgname={desktop} />}
            {sizes.width < 1200 && sizes.width >800 && <Layers pos={[60, 0, -20]} args={[22, 11]} imgname={desktop} />}
            {sizes.width < 800 && <Layers pos={[60, 2, -20]} args={[8,15]} imgname={mobile} />}
            {/* <Layers pos={[0, 0, -20]} args={[30, 15]} imgname={l3} /> */}

            <Layers pos={[60, 0, -15]} args={[60, 16.5]} imgname={l2} />
            {/* <Layers pos={[30, 0, -13]} args={[30, 15]} imgname={l2} /> */}

            <Layers pos={[60, 0, -7]} args={[20, 10]} imgname={l1} />
            <Layers pos={[80, 0, -7]} args={[20, 10]} imgname={l1} />
            <Layers pos={[100, 0, -7]} args={[20, 10]} imgname={l1} />
        </>
    )
}