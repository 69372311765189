import React from "react";
import "../css/journey.css";
import { useState } from "react";
import { data } from "../constants";
import images from "../constants/images";
import Navbar from "../components/navbar";

const Journey = () => {
  const handleYearClick = (year) => {
    const newActiveID = data.journey.findIndex(
      (timestamp) => timestamp.year === year
    );
    setActiveID(newActiveID);
  };

  const handleUpClick = () => {
    if (activeID === 0) {
      setActiveID(data.journey.length - 1);
    } else {
      setActiveID(activeID - 1);
    }
  };

  const handleDownClick = () => {
    if (activeID === data.journey.length - 1) {
      setActiveID(0);
    } else {
      setActiveID(activeID + 1);
    }
  };

  const [activeID, setActiveID] = useState(0);

  return (
    <>
    <Navbar/>
      <div className="app__journey" id="journey">
        <div className="app__journey-content">
          <button className="col mobilearrows__journey">
            <img
              onClick={() => handleUpClick()}
              src={images.leftArrow}
              alt="upArrow"
            ></img>
          </button>
          <div className="row app__journey-content_left">
            <div className="col-xl-3 col-lg-4 col-12 app__journey-content__text">
              <h1 className="headtext__cormorant">
                {data.journey[activeID].year}
              </h1>
              <p className="p__cormorant">{data.journey[activeID].content}</p>
            </div>
            <div className="col-xl-9 col-lg-8 col-12 app__journey-content__img">
              <img
                src={data.journey[activeID].img}
                alt=""
                className="images__journey"
              />
            </div>
          </div>
          <button className="col mobilearrows__journey">
            <img
              onClick={() => handleDownClick()}
              src={images.rightArrow}
              alt="DownArrow"
            ></img>
          </button>
          <div className="app__journey-content_right">
            <button className="arrows__journey">
              <img
                onClick={() => handleUpClick()}
                src={images.upArrow}
                alt="upArrow"
              ></img>
            </button>
            <div className="timeline__journey">
                {data.journey.map((timestamp) => {
                  return (
                    <button
                      key={timestamp.id}
                      className="year__journey"
                      onClick={() => handleYearClick(timestamp.year)}
                    >
                      {timestamp.year}
                    </button>
                  );
                })}
            </div>
            <button className="arrows__journey">
              <img
                onClick={() => handleDownClick()}
                src={images.downArrow}
                alt="downArrow"
              ></img>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Journey;