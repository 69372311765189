import React from "react";
import "../css/gallery.css";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Navbar from "../components/navbar";

const Gallery = () => {
  const [galleryItems, setgalleryItems] = useState([]);

  useEffect(() => {
    let galleryItemsArray = [];
    getDocs(collection(db, "gallery")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let galleryItem = {
          imgLink: doc.data().imgLink,
          likes: doc.data().likes,
        };
        galleryItemsArray.push(galleryItem);
      });
      setgalleryItems(galleryItemsArray);
    });
  }, []);

  var width = window.innerWidth;
  var splits = 1;
  if (width > 1400) splits = 5;
  else if (width > 1150) splits = 4;
  else if (width > 840) splits = 3;
  else if (width > 550) splits = 2;

  var subArrays = [];
  for (let i = 0; i < galleryItems.length; i += splits) {
    subArrays.push(galleryItems.slice(i, i + splits));
  }

  return (
    <>
      <Navbar />
      <div className="app__gallery">
        {subArrays.map((images) => (
          <div className="gallery__container">
            {images.map((image) => (
              <div className="gallery__image-card">
                <LazyLoadImage src={image.imgLink} alt="gallery_image" />
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default Gallery;
