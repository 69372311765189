import "./css/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Gallery,
  StarAttractions,
  Journey,
  Schedule,
  Events,
  Media,
  Aftermovies,
  Sponsors,
  Brochure,
  Queries,
  Associates,
  Contacts,
  Merch,
} from "./pages";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Navbar /> */}
        {/* <CustomNav /> */}
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/gallery" element={<Gallery />}></Route>
          <Route exact path="/schedule" element={<Schedule />}></Route>
          <Route
            exact
            path="/starattractions"
            element={<StarAttractions />}
          ></Route>
          <Route exact path="/journey" element={<Journey />}></Route>
          <Route exact path="/events" element={<Events />}></Route>
          <Route exact path="/media" element={<Media />}></Route>
          <Route exact path="/brochure" element={<Brochure />}></Route>
          <Route exact path="/aftermovies" element={<Aftermovies />}></Route>
          <Route exact path="/sponsors" element={<Sponsors />}></Route>
          <Route exact path="/queries" element={<Queries />}></Route>
          <Route exact path="/associates" element={<Associates />}></Route>
          <Route exact path="/merch" element={<Merch />}></Route>
          <Route exact path="/contacts" element={<Contacts />}></Route>
          <Route
            exact
            path="/starAttractions"
            element={<StarAttractions />}
          ></Route>
          {/* <Route exact path="/contacts" element={<Contact />}></Route> */}

          <Route
            exact
            path="*"
            element={<h1>Error 404: Page Not Found</h1>}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
