import alumni from '../assets/events/alumni.jpg';
import comickaun from '../assets/events/comickaun.jpg';
import dance from '../assets/events/dance.jpg';
import debate from '../assets/events/debate.jpg';
import drams from '../assets/events/drams.jpg';
import ele from '../assets/events/ele.jpg';
import finearts from '../assets/events/finearts.jpg';
import fnp from '../assets/events/fnp.jpg';
import hle from '../assets/events/hle.jpg';
import mrm from '../assets/events/mrm.jpg';
import music from '../assets/events/music.jpg';
import prodigy from '../assets/events/prodigy.jpg';
import quiz from '../assets/events/quiz.jpg';
import ritambhara from '../assets/events/ritambhara.jpg';
import warfire from '../assets/events/warfire.jpg';

import typeface from '../assets/Typeface_png.png';

import upArrow from "../assets/up-arrow.png";
import downArrow from "../assets/down-arrow.png";
import rightArrow from "../assets/right-arrow.png";
import leftArrow from "../assets/left-arrow.png";

import journey01 from '../assets/journey/1.jpg';
import journey02 from '../assets/journey/2.jpg';
import journey03 from '../assets/journey/3.jpg';
import journey04 from '../assets/journey/4.jpg';
import journey05 from '../assets/journey/5.jpg';
import journey06 from '../assets/journey/6.jpg';
import journey07 from '../assets/journey/7.jpg';
import journey08 from '../assets/journey/8.jpg';


const images = {
    alumni,
    comickaun,
    dance,
    debate,
    drams,
    ele,
    finearts,
    fnp,
    hle,
    mrm,
    music,
    prodigy,
    quiz,
    ritambhara,
    warfire,

    typeface,

    journey01,
    journey02,
    journey03,
    journey04,
    journey05,
    journey06,
    journey07,
    journey08,

    upArrow,
    downArrow,
    rightArrow,
    leftArrow,
};

export default images;