import React from "react";
import "../css/contactCard.css";
export default function ContactCard(props) {
  const contact = props.contact;
    return(
        <>
       
  <li>
    <a href="" className="cardcon">
      <img src={contact.image} className="card__imagecon" alt={contact.name} />
      <div className="card__overlaycon">
        <div className="card__headercon">
                               
          <img className="card__thumbcon" src={contact.image} alt="" />
          <div className="card__header-textcon">
            <h3 className="card__titlecon">{contact.name}</h3>            
            <span className="card__statuscon">{contact.post}</span>
          </div>
        </div>

        <div className="card__descriptioncon">
          
          <div className="fa fa-icon"></div>
        </div>
      </div>
    </a>      
  </li>
 

</>
    )
}