import React from "react";
import "./nav.css"

export default function Nav3d(props) {
  const posts = props.posts;
  const onClick = () => {}
    return (
        <>
        <div className="glowNav3d"></div>
        <div className="pole-containerNav3d">
          <div className ="poleNav3d"></div>
          <div className ="sign-1 signNav3d"><a  className="aNav3d" target = "" href="#1">Events</a></div>
          <div className = "sign-2 signNav3d"><a className="aNav3d" target = "" href = "#2">Finance</a></div>
          <div className = "sign-3 signNav3d"><a className="aNav3d" target = "" href = "#3">Marketing</a></div>
          <div className = "sign-4 signNav3d"><a className="aNav3d" target = "" href = "#4">MnP</a></div>
          <div className ="sign-5 signNav3d"><a  className="aNav3d" target = "" href="#5">P.R.</a></div>
          <div className = "sign-6 signNav3d"><a className="aNav3d" target = "" href = "#6">Security</a></div>
          <div className = "sign-7 signNav3d"><a className="aNav3d" target = "" href = "#7">Show_M.</a></div>
          <div className = "sign-8 signNav3d"><a className="aNav3d" target = "" href = "#8">Design</a></div>
          <div className = "sign-9 signNav3d"><a className="aNav3d" target = "" href = "#9">Web</a></div>
          <div className = "sign-10 signNav3d"><a className="aNav3d" target = "" href = "#10">Festival Coordinator</a></div>
        </div> 
        </>
    )
}