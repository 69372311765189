import React from "react";
import { data } from "../constants";
import "../css/events.css";
import Navbar from "../components/navbar";

const Events = () => {
  return (
    <>
      <Navbar />
      <div className="app__events" id="events">
        <div class="btn__wrapperReg">
          <div style={{ position: "relative" }}>
            <button
              className="btn__register"
              onClick={() => {
                window.open("https://events.antaragni.in/landing", "_blank");
              }}
            >
              Register Now
            </button>
            <div class="icon__RegBtn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 268.832 268.832"
              >
                <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-center row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1">
            {data.events.map((event) => (
              <div className="col ps-2 pe-2 mt-4">
                <div className="events__box">
                  <img
                    className="events__img"
                    src={event.image}
                    alt={event.name}
                  ></img>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
