import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import img1 from "../assets/brochure/1.png";
import img2 from "../assets/brochure/2.png";
import img3 from "../assets/brochure/3.png";
import img4 from "../assets/brochure/4.png";
import img5 from "../assets/brochure/5.png";
import img6 from "../assets/brochure/6.png";
import img7 from "../assets/brochure/7.png";
import img8 from "../assets/brochure/8.png";
import img9 from "../assets/brochure/9.png";
import img10 from "../assets/brochure/10.png";
import img11 from "../assets/brochure/11.png";
import img12 from "../assets/brochure/12.png";
import img13 from "../assets/brochure/13.png";
import img14 from "../assets/brochure/14.png";
import img15 from "../assets/brochure/15.png";
import img16 from "../assets/brochure/16.png";
import img17 from "../assets/brochure/17.png";
import img18 from "../assets/brochure/18.png";
import img19 from "../assets/brochure/19.png";
import img20 from "../assets/brochure/20.png";
import img21 from "../assets/brochure/21.png";
import img22 from "../assets/brochure/22.png";
import img23 from "../assets/brochure/23.png";
import img24 from "../assets/brochure/24.png";
import img25 from "../assets/brochure/25.png";
import img26 from "../assets/brochure/26.png";
import img27 from "../assets/brochure/27.png";
import img28 from "../assets/brochure/28.png";
import img29 from "../assets/brochure/29.png";
import img30 from "../assets/brochure/30.png";
import img31 from "../assets/brochure/31.png";
import img32 from "../assets/brochure/32.png";
import img33 from "../assets/brochure/33.png";
import img34 from "../assets/brochure/34.png";
import img35 from "../assets/brochure/35.png";
import img36 from "../assets/brochure/36.png";
import img37 from "../assets/brochure/37.png";
import img38 from "../assets/brochure/38.png";
import img39 from "../assets/brochure/39.png";
import img40 from "../assets/brochure/40.png";
import img41 from "../assets/brochure/41.png";
import img42 from "../assets/brochure/42.png";
import img43 from "../assets/brochure/43.png";
import img44 from "../assets/brochure/44.png";
import img45 from "../assets/brochure/45.png";
import img46 from "../assets/brochure/46.png";
import img47 from "../assets/brochure/47.png";
import img48 from "../assets/brochure/48.png";

import "../css/brochure.css";

import Navbar from "../components/navbar";

const items = [
  <div className="item" data-value="1">
    <img src={img1}></img>
  </div>,
  <div className="item" data-value="2">
    <img src={img2}></img>
  </div>,
  <div className="item" data-value="3">
    <img src={img3}></img>
  </div>,
  <div className="item" data-value="4">
    <img src={img4}></img>
  </div>,
  <div className="item" data-value="5">
    <img src={img5}></img>
  </div>,
  <div className="item" data-value="6">
    <img src={img6}></img>
  </div>,
  <div className="item" data-value="7">
    <img src={img7}></img>
  </div>,
  <div className="item" data-value="8">
    <img src={img8}></img>
  </div>,
  <div className="item" data-value="9">
    <img src={img9}></img>
  </div>,
  <div className="item" data-value="10">
    <img src={img10}></img>
  </div>,
  <div className="item" data-value="11">
    <img src={img11}></img>
  </div>,
  <div className="item" data-value="12">
    <img src={img12}></img>
  </div>,
  <div className="item" data-value="13">
    <img src={img13}></img>
  </div>,
  <div className="item" data-value="14">
    <img src={img14}></img>
  </div>,
  <div className="item" data-value="15">
    <img src={img15}></img>
  </div>,
  <div className="item" data-value="16">
    <img src={img16}></img>
  </div>,
  <div className="item" data-value="17">
    <img src={img17}></img>
  </div>,
  <div className="item" data-value="18">
    <img src={img18}></img>
  </div>,
  <div className="item" data-value="19">
    <img src={img19}></img>
  </div>,
  <div className="item" data-value="20">
    <img src={img20}></img>
  </div>,
  <div className="item" data-value="21">
    <img src={img21}></img>
  </div>,
  <div className="item" data-value="22">
    <img src={img22}></img>
  </div>,
  <div className="item" data-value="23">
    <img src={img23}></img>
  </div>,
  <div className="item" data-value="24">
    <img src={img24}></img>
  </div>,
  <div className="item" data-value="25">
    <img src={img25}></img>
  </div>,
  <div className="item" data-value="26">
    <img src={img26}></img>
  </div>,
  <div className="item" data-value="27">
    <img src={img27}></img>
  </div>,
  <div className="item" data-value="28">
    <img src={img28}></img>
  </div>,
  <div className="item" data-value="29">
    <img src={img29}></img>
  </div>,
  <div className="item" data-value="30">
    <img src={img30}></img>
  </div>,
  <div className="item" data-value="31">
    <img src={img31}></img>
  </div>,
  <div className="item" data-value="32">
    <img src={img32}></img>
  </div>,
  <div className="item" data-value="33">
    <img src={img33}></img>
  </div>,
  <div className="item" data-value="34">
    <img src={img34}></img>
  </div>,
  <div className="item" data-value="35">
    <img src={img35}></img>
  </div>,
  <div className="item" data-value="36">
    <img src={img36}></img>
  </div>,
  <div className="item" data-value="37">
    <img src={img37}></img>
  </div>,
  <div className="item" data-value="38">
    <img src={img38}></img>
  </div>,
  <div className="item" data-value="39">
    <img src={img39}></img>
  </div>,
  <div className="item" data-value="40">
    <img src={img40}></img>
  </div>,
  <div className="item" data-value="41">
    <img src={img41}></img>
  </div>,
  <div className="item" data-value="42">
    <img src={img42}></img>
  </div>,
  <div className="item" data-value="43">
    <img src={img43}></img>
  </div>,
  <div className="item" data-value="44">
    <img src={img44}></img>
  </div>,
  <div className="item" data-value="45">
    <img src={img45}></img>
  </div>,
  <div className="item" data-value="46">
    <img src={img46}></img>
  </div>,
  <div className="item" data-value="47">
    <img src={img47}></img>
  </div>,
  <div className="item" data-value="48">
    <img src={img48}></img>
  </div>,
];

const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
  return items.map((item, i) => (
    <div
      className="thumb"
      onClick={() => (setThumbIndex(i), setThumbAnimation(true))}
    >
      {i + 1}
    </div>
  ));
};

const Brochure = () => {
  const [mainIndex, setMainIndex] = useState(0);
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const [thumbs] = useState(
    thumbItems(items, [setThumbIndex, setThumbAnimation])
  );

  const slideNext = () => {
    if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
      // console.log(thumbs.length);
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    }
  };

  const slidePrev = () => {
    if (!thumbAnimation && thumbIndex > 0) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex - 1);
    }
  };

  const syncMainBeforeChange = (e) => {
    setMainAnimation(true);
  };

  const syncMainAfterChange = (e) => {
    setMainAnimation(false);

    if (e.type === "action") {
      setThumbIndex(e.item);
      setThumbAnimation(false);
    } else {
      setMainIndex(thumbIndex);
    }
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);

    if (!mainAnimation) {
      setMainIndex(e.item);
    }
  };

  return [
    <>
      <Navbar />
      <div className="brochure-container">
        <AliceCarousel
          activeIndex={mainIndex}
          animationType="fadeout"
          autoWidth
          animationDuration={5}
          // disableDotsControls
          disableButtonsControls
          items={items}
          mouseTracking={true}
          onSlideChange={syncMainBeforeChange}
          onSlideChanged={syncMainAfterChange}
          touchTracking={true}
        />

        <div className="thumbs">
          <AliceCarousel
            activeIndex={thumbIndex}
            autoHeight
            disableDotsControls
            disableButtonsControls
            items={thumbs}
            mouseTracking={true}
            onSlideChanged={syncThumbs}
            touchTracking={true}
          />
          <div className="btn-prev" onClick={slidePrev}>
            &lang;
          </div>
          <div className="btn-next" onClick={slideNext}>
            &rang;
          </div>
        </div>
      </div>
      ,
    </>,
  ];
};

export default Brochure;
