import React from "react";
import '../css/associateForm.css'
import { db } from '../firebaseConfig'
import { useState } from "react";
import { addDoc, collection } from 'firebase/firestore'

export default function Associates() {
  // 3d effect start
  const pre = document.querySelector(".container2")

  document.addEventListener("mousemove", (e) => {
    rotateElement(e, pre);
  });

  function rotateElement(event, element) {
    const x = event.clientX;
    const y = event.clientY;

    const middleX = window.innerWidth / 2;
    const middleY = window.innerHeight / 2;

    const offsetX = ((x - middleX) / middleX) * 45;
    const offsetY = ((y - middleY) / middleY) * 45;
    // console.log(offsetX, offsetY);

    // set rotation
    element.style.setProperty("--rotateX", -1 * offsetY + "deg");
    element.style.setProperty("--rotateY", offsetX + "deg");
  }

  // const checkbox = document.querySelector('.my-form input[type="checkbox"]');
  // const btns = document.querySelectorAll(".my-form button");

  // checkbox.addEventListener("change", function () {
  //   const checked = this.checked;
  //   for (const btn of btns) {
  //     checked ? (btn.disabled = false) : (btn.disabled = true);
  //   }
  // });
  //3d effect end

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [addrtype, setAddrtype] = useState(["Select cell to associate with", "Public Relation", "Marketing", "Media and Publicity", "Events", "Show Management"])
  const [cell, setCell] = useState("");
  const Add = addrtype.map(Add => Add)
  const handleAddrTypeChange = (e) => {
    // console.log((addrtype[e.target.value]));
    setCell(addrtype[e.target.value]);
  };
  const userData = collection(db, "Associates")

  const handleSubmit = () => {
    addDoc(userData, {
      cell: cell,
      name: name,
      email: email,
      comapany: company,
      phone: phone,
      message: message,
    })
  }


  return (
    <form className="my-form">
      <div className="container2">
        <h1 className="tokenHead">ASSOCIATE WITH US</h1>
        <ul>
        <div className="gridGrid-1">
        <select className="selectDiv" onChange={e => handleAddrTypeChange(e)}>
            {
              Add.map((address, key) => <option key={key} value={key}>{address}</option>)
            }
          </select>
        </div>
          

          <div className="gridGrid-2">
            <label>Company</label>
            <input type="text" placeholder="Company Name" required
              onChange={(event) => {
                setCompany(event.target.value)
              }} />
          </div>

          <div className="gridGrid-2">
            <label>Name</label>
            <input type="text" placeholder="Tom" required
              onChange={(event) => {
                setName(event.target.value)
              }} />
          </div>

          <div className="gridGrid-2">
            <label>Email</label>
            <input type="email" placeholder="tom@gmail.com" required
              onChange={(event) => {
                setEmail(event.target.value)
              }} />
          </div>

          <div className="gridGrid-2">
            <label>Phone</label>
            <input type="tel" placeholder="82398***13"
              onChange={(event) => {
                setPhone(event.target.value)
              }} />
          </div>

          <div className="gridGrid-2">
            <label>Message</label>
            <textarea placeholder="Message"
              onChange={(event) => {
                setMessage(event.target.value)
              }}></textarea>
          </div>
         

          <div className="gridGrid-3">
            <div className="box-3">
              <button onClick={handleSubmit} className="btn btn-three">
                <span style={{"color":"white"}} >Submit</span>
              </button>
            </div>
            {/* <div className="box-3">
              <div className="btn btn-three">
                <span>Reset</span>
              </div>
            </div> */}
          </div>

        </ul>
      </div>
    </form>
  );
};
