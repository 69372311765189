import * as THREE from 'three'
import React, { Suspense, useEffect } from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import typeface from '../assets/Typeface_png.png'
import logo from '../assets/logo.png'
import { useFrame } from '@react-three/fiber'
import { Scroll, ScrollControls, useProgress, OrbitControls } from "@react-three/drei";
import { useRef, useState } from 'react'
import { useThree } from "@react-three/fiber";
// import Gallery from "../pages/gallery.tsx";
import { Html } from '@react-three/drei'
import Screen1 from './screen1'
import Screen2 from './screen2'
import Screen3 from './screen3'
import Screen4 from './screen4'
import "./style.css"
import Associateimg from '../assets/screen3/Assosciate.png'
import { Associates } from '../pages'


function AssociatesButton(data) {
    
  const ref = useRef();
          const [hovered, setHovered] = useState(false)
          const texture = useLoader(THREE.TextureLoader, data.imgname)
          useFrame(() => {
              if (hovered) {
                ref.current.scale.set(1.1, 1.1, 1.1)
                
              } else {
                ref.current.scale.set(1, 1, 1)
              }
            })
          const sizes = {
              width: window.innerWidth,
              height: window.innerHeight
          }
          const onClick = () => {data.setShow(true)};
          return (
          <mesh ref={ref} position={data.pos} onClick={onClick}  onPointerOver = {()=> {setHovered(true)}} onPointerOut = {()=> {setHovered(false)}}>

              <planeBufferGeometry attach="geometry" args={data.args} />
              <meshBasicMaterial attach="material" map={texture} transparent />
          </mesh>
          )
}


function Groupall(args) {
  // console.log(args.showargs);
  const groupRef = useRef()
  const { width } = useThree((state) => state.viewport);
  const illustrations = [1, 2, 3, 4, 5, 6, 7];
  
  // console.log(width, xW)
  // useFrame((state, delta) => {
  //   groupRef.current.position.x = -((-groupRef.current.position.x + .05) % 100)
  // })
  return (
    <>

      <ScrollControls
        horizontal
        
        distance={0.5}
        factor={10}
        // infinite
        pages={190/width}
      // pages={(width - xW + illustrations.length * xW) / width}
      >
          <Scroll>
            <group damping={4}>
            <Screen1 />
            <Screen2 />
            <Screen3 />
            <Screen4 />
            <AssociatesButton pos={[173,-4,-10]} args={[8,8]} imgname={Associateimg} setShow={args.setShow}/>
            </group>  
          </Scroll>
      </ScrollControls>

    </>
  )
}

function Rig() {
  const { camera, mouse } = useThree()
  const vec = new THREE.Vector3()
  return useFrame(() => camera.position.lerp(vec.set(mouse.x * 0.5, camera.position.y, camera.position.z), 0.02))
}

function Loader() {
  const { active, progress, errors, item, loaded, total } = useProgress()
  return <Html center>
    <img src={require('../assets/loading.gif')} alt="loading..." />
    <h1 style={{"color":"white"}}>{Math.floor(progress)} % loading</h1>  
    </Html>
}


export default function Landing() {
  const [showa, setShow] = useState(false);

  const ref = useRef()

  const scrollRef = useRef()
  const scroll = useRef(0)

    // console.log(setShow);
  return (
    <div style={{ "height": "100vh" ,   "backgroundColor":"#2f2f2f", "zIndex":"0"}} >
      <Canvas
        gl={{ powerPreference: 'high-performance', antialias: false, alpha: false }}
        camera={{ position: [0, 0, 5], fov: 45 }}
        >

        <Suspense fallback={<Loader/>}>
         
          <Groupall setShow={setShow}/>

        </Suspense>
        {/* <OrbitControls /> */}
        {/* <Stats /> */}
        <Rig />
       
      </Canvas>
     {
      showa == true &&  <div className='associateForm' >
      <a className="closebtn" onClick={()=> setShow(false)}>
            &times;
          </a>
        <Associates />
      </div>
     }
      
    </div>
  )
}
