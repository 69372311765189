import * as THREE from 'three'
import React, { Suspense, useEffect } from 'react'
import { useLoader } from '@react-three/fiber'
import { useFrame } from '@react-three/fiber'
import { useRef, useState } from 'react'
import { useThree } from "@react-three/fiber";
// import Gallery from "../pages/gallery.tsx";
import { Html } from '@react-three/drei'
import l1 from '../assets/screen3/21.png'
import l2 from '../assets/screen3/22.png'
import l3 from '../assets/screen1/3.png'
import l4 from '../assets/screen3/24.png'
import l5 from '../assets/screen3/25.png'
import { useVideoTexture, useTexture } from '@react-three/drei'
import Discover from "../assets/screen3/Discover.png";

function Layers(data) {
            const texture = useLoader(THREE.TextureLoader, data.imgname)
            const sizes = {
                width: window.innerWidth,
                height: window.innerHeight
            }
            return (
            <mesh position={data.pos}>

                <planeBufferGeometry attach="geometry" args={data.args} />
                <meshBasicMaterial attach="material" map={texture} transparent />
            </mesh>
            )
}


function Scene(props) {
  const ref = useRef()
  const texture = useVideoTexture("/11.mp4")
  const [active, setActive] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [args1, setArgs] = useState([5,5]);
  const onClicked = () => {
    setActive(!active)
    if(active){ setPos([108,-3,-18]); setArgs([5,5])}
    else{setPos([115,0,-12]); setArgs([5,10])}
  }
  useFrame(() => {
    if (hovered) {
      ref.current.scale.set(1.1, 1, 1)
    } else {
      ref.current.scale.set(1, 1, 1)
    }
  })
  const [pos , setPos] = useState([108,-5,-18])
    return (
      <mesh ref={ref} position={pos} onClick={(e) => (e.stopPropagation, onClicked())} onPointerMissed = {()=> {setPos([108,-3,-18]) ;setArgs([5,5])}} onPointerOver = {()=> {setHovered(true)}} onPointerOut = {()=> {setHovered(false)}}>
      <planeGeometry args={args1}/>
        <Suspense fallback={<FallbackMaterial url="/5.png" />}>
          <VideoMaterial url={props.url}  />
          <meshBasicMaterial map={texture} toneMapped={false} />
        </Suspense>
      </mesh>
    )
  }
  function Scene2() {
    const [args1, setArgs] = useState([5,5]);
    const ref = useRef()
    const texture = useVideoTexture("/12.mp4")
    const [active, setActive] = useState(false)
    const [hovered, setHovered] = useState(false)

    const onClicked = () => {
      setActive(!active)
      if(active){ setPos([113,-5,-24]); setArgs([5,5])}
      else{setPos([117,0,-12]);setArgs([5,10])}
    }
    useFrame(() => {
      if (hovered) {
        ref.current.scale.set(1.1, 1, 1)
      } else {
        ref.current.scale.set(1, 1, 1)
      }
    })
    const [pos , setPos] = useState([112,-5,-24])
    return (
      <mesh ref={ref} position={pos} onClick={(e) => (e.stopPropagation, onClicked())} onPointerMissed = {()=> {setPos([113,-3.5,-24]); setArgs([5,5])}} onPointerOver = {()=> {setHovered(true)}} onPointerOut = {()=> {setHovered(false)}}>
        <planeGeometry args={args1} />
        <Suspense fallback={<FallbackMaterial url="/5.png" />}>
          <VideoMaterial url="/12.mp4"/>
          <meshBasicMaterial map={texture} toneMapped={false}  />
        </Suspense>
      </mesh>
    )
  }
  
  function Scene3() {
    const [args1, setArgs] = useState([5,5]);
    const ref= useRef()
    const texture = useVideoTexture("/11.mp4"  )
    const [active, setActive] = useState(false)
    const [hovered, setHovered] = useState(false)

    const onClicked = () => {
      setActive(!active)
      if(active){ setPos([120,-2.5,-15]); setArgs([5,5])}
      else{setPos([120,0,-8]);setArgs([5,10])}
    }
    useFrame(() => {
      if (hovered) {
        ref.current.scale.set(1.1, 1, 1)
      } else {
        ref.current.scale.set(1, 1, 1)
      }
    })
    const [pos , setPos] = useState([120,-3.5,-15])
    return (
      <mesh ref={ref} position={pos} onClick={(e) => (e.stopPropagation, onClicked())} onPointerMissed = {()=> {setPos([120,-2.5,-15]); setArgs([5,5])}} onPointerOver = {()=> {setHovered(true)}} onPointerOut = {()=> {setHovered(false)}}>
        <planeGeometry args={args1}/>
        <Suspense fallback={<FallbackMaterial url="/5.png" />}>
          <VideoMaterial url="/11.mp4" />
          <meshBasicMaterial map={texture} toneMapped={false} />
        </Suspense>
      </mesh>
    )
  }
  function Scene4() {
    const [args1, setArgs] = useState([5,5]);
    const ref= useRef()
    const texture = useVideoTexture("/12.mp4"  )
    const [active, setActive] = useState(false)
    const [hovered, setHovered] = useState(false)

    const onClicked = () => {
      setActive(!active)
      if(active){ setPos([127,-5,-24]); setArgs([5,5])}
      else{setPos([124,0,-8]); setArgs([5,10])}
    }
    useFrame(() => {
      if (hovered) {
        ref.current.scale.set(1.1, 1, 1)
      } else {
        ref.current.scale.set(1, 1, 1)
      }
    })
    const [pos , setPos] = useState([127,-5,-24])
    return (
      <mesh ref={ref} position={pos} onClick={(e) => (e.stopPropagation, onClicked())} onPointerMissed = {()=> {setPos([127,-3.5,-24]); setArgs([5,5])}} onPointerOver = {()=> {setHovered(true)}} onPointerOut = {()=> {setHovered(false)}}>
        <planeGeometry args={args1}/>
        <Suspense fallback={<FallbackMaterial url="/5.png" />}>
          <VideoMaterial url="/12.mp4" />
          <meshBasicMaterial map={texture} toneMapped={false} />
        </Suspense>
      </mesh>
    )
  }
  function Scene5() {
    const [args1, setArgs] = useState([5,5]);
    const ref= useRef()
    const texture = useVideoTexture("/11.mp4"  )
    const [active, setActive] = useState(false)
    const [hovered, setHovered] = useState(false)

    const onClicked = () => {
      setActive(!active)
      if(active){ setPos([132,-3,-18]); setArgs([5,5])}
      else{setPos([129,0,-8]); setArgs([5,10])}
    }
    useFrame(() => {
      if (hovered) {
        ref.current.scale.set(1.1, 1, 1)
      } else {
        ref.current.scale.set(1, 1, 1)
      }
    })
    const [pos , setPos] = useState([132,-5,-18])
    return (
      <mesh ref={ref} position={pos} onClick={(e) => (e.stopPropagation, onClicked())} onPointerMissed = {()=> {setPos([132,-3,-18]); setArgs([5,5])}} onPointerOver = {()=> {setHovered(true)}} onPointerOut = {()=> {setHovered(false)}}>
        <planeGeometry args={args1}/>
        <Suspense fallback={<FallbackMaterial url="/5.png" />}>
          <VideoMaterial url="/11.mp4" />
          <meshBasicMaterial map={texture} toneMapped={false} />
        </Suspense>
      </mesh>
    )
  }
  function VideoMaterial({ url }) {
    const texture = useVideoTexture(url)
    return <meshBasicMaterial map={texture} toneMapped={false}  />
  }
  
  function FallbackMaterial({ url }) {
    const texture = useTexture(url)
    return <meshBasicMaterial map={texture} toneMapped={false} />
  }
  
export default function Screen2() {
    return (
        <>
        <Scene url="/11.mp4"/>
        <Scene2/>
        <Scene3/>
        <Scene4/>
        <Scene5/>
            <Layers pos={[120,-0.25,-30]} args={[60,30]} imgname={l5}/>
            <Layers pos={[120,0,-25]} args={[50,25]} imgname={l4}/>
            
            <Layers pos={[120,5,-15]} args={[10,10]} imgname={Discover}/>
            
            <Layers pos={[110,0,-13]} args={[30,15]} imgname={l2}/>
            <Layers pos={[145,0,-13]} args={[30,15]} imgname={l2}/>

            <Layers pos={[120,0,-7]}  args={[20,10]} imgname={l1}/>
            <Layers pos={[140,0,-7]}  args={[20,10]} imgname={l1}/>
            <Layers pos={[160,0,-7]}  args={[20,10]} imgname={l1}/>
        </>
    )
}