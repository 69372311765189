import { useEffect, useState } from "react";
import styles from "../css/media.module.css";
const MediaCard = ({ mediaItem }) => {
  // console.log(mediaItem);
  return (
    <li>
      <div>
        <code></code>
        <text>
          <h2>{mediaItem.portalName}</h2>
          <img
            src={mediaItem.portalImgLink}
            width="200"
            height="130"
            alt="Placeholder"
          />
          <p>{mediaItem.data.slice(0, 120)}</p>
          <a href={mediaItem.articleLink}>VIEW MORE</a>
        </text>
      </div>
    </li>
  );
};

export default MediaCard;
