import styles from "../css/queries.module.css";

const QueryCard = ({ query }) => {
  // console.log(query);
  return (
    <div className={styles.card}>
      <img src={query.imgLink} alt="Contact" className={styles.queryImage} />
      <h4>{query.name}</h4>
      <span>Organizer, Hospitality And Transport</span>
      <span>
        <a href={`tel:${query.mobile}`}>{`Mobile: ${query.mobile}`}</a>
      </span>
    </div>
  );
};

export default QueryCard;
