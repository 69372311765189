import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC4ap8EkWKRiblEiyVF91Rv6P7dgC75JC8",
  authDomain: "antaragniflame.firebaseapp.com",
  projectId: "antaragniflame",
  storageBucket: "antaragniflame.appspot.com",
  messagingSenderId: "287791400652",
  appId: "1:287791400652:web:c35619be45591703a5e469",
  measurementId: "G-WY6SLW9NM7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };
